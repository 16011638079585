import React from "react";

import './Menu.css';
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";

export const Menu: React.FC = (): JSX.Element => {

    return (
        <>
            <Helmet>
                <title>Menu - Hallwylska Restaurang</title>
                <meta name="description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/menu" />
                <meta property="og:title" content="Menu - Hallwylska Restaurang" />
                <meta property="og:description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <meta property="og:image" content="https://hallwylskarestaurang.com/img/menu/julbord.png" />
                <meta property="og:url" content="https://hallwylskarestaurang.com/menu" />
                <meta name="twitter:title" content="Menu - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Explore the delicious offerings on the menu at Hallwylska Restaurang. View our seasonal specials and classic dishes." />
                <meta name="twitter:image" content="https://hallwylskarestaurang.com/img/menu/julbord.png" />
                
            </Helmet>
            <section className="menu container pt-5">
                <img src="/img/menu/meny1300x.png" 
                     alt="A la carte at hallwylska"/>
                <img src="/img/menu/meny2300x.png" 
                     alt="A la carte at hallwylska"/>
                {/* <h1>Menu</h1>
		
                <div className="section">
                    <h2>Snacks</h2>
                    <div className="item"><span>Olives</span><span className="price">60kr</span></div>
                    <div className="item"><span>Almonds</span><span className="price">65kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Cheese</h2>
                    <div className="item"><span>Gruyère</span><span className="price">75kr</span></div>
                    <div className="item"><span>Swedish Brie Sörmland</span><span className="price">75kr</span></div>
                    <div className="item"><span>Swedish Blue Cheese Skåne</span><span className="price">75kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Cold Cuts</h2>
                    <div className="item"><span>Fuet collar</span><span className="price">85kr</span></div>
                    <div className="item"><span>Chorizo</span><span className="price">85kr</span></div>
                    <div className="item"><span>Petite Saucisse</span><span className="price">85kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Caviar & Oysters</h2>
                    <div className="item"><span>Ostron au naturelle 3/6/12</span><span className="price">105/200/390kr</span></div>
                    <div className="item"><span>Caviar Husa gold 30g</span><span className="price">490kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Light Starters</h2>
                    <div className="item"><span>Escargot (L)</span><span className="price">215kr</span></div>
                    <div className="item"><span>Crudité (N)(E)(*)</span><span className="price">165kr</span></div>
                    <div className="item"><span>Boquerones (G)</span><span className="price">135kr</span></div>
                    <div className="item"><span>Tomatoes (V)(N)</span><span className="price">175kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Starters</h2>
                    <div className="item"><span>Pâté en croûte au Foie Gras (G)</span><span className="price">240kr</span></div>
                    <div className="item"><span>Vitello Tonnato (E)</span><span className="price">320kr</span></div>
                    <div className="item"><span>Moules Marinière</span><span className="price">195/325kr</span></div>
                    <div className="item"><span>Tartare (E)</span><span className="price">195/295kr</span></div>
                    <div className="item"><span>Red shrimp (G)(E)</span><span className="price">245kr</span></div>
                    <div className="item"><span>Salsify (*)(N)</span><span className="price">185kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Mains</h2>
                    <div className="item"><span>Steak au poivre (L)</span><span className="price">435kr</span></div>
                    <div className="item"><span>French lobster (L)(E)</span><span className="price">450/900kr</span></div>
                    <div className="item"><span>Salad Niçoise (E)</span><span className="price">245kr</span></div>
                    <div className="item"><span>Insalata mozzarella (L)</span><span className="price">195kr</span></div>
                    <div className="item"><span>Seabass Meunière (G)(L)</span><span className="price">275kr</span></div>
                    <div className="item"><span>Croque monsieur (G)(L)</span><span className="price">195kr</span></div>
                    <div className="item"><span>Baked Potato with Truffles (*)(L)</span><span className="price">375kr</span></div>
                    <div className="item"><span>Baked Potato with Caviar (L)</span><span className="price">595kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Dessert</h2>
                    <div className="item"><span>Crème brûlée (G)(L)(E)</span><span className="price">125kr</span></div>
                    <div className="item"><span>Mousse au chocolat (V)</span><span className="price">115kr</span></div>
                    <div className="item"><span>Sorbet au citron (V)</span><span className="price">70kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Sides</h2>
                    <div className="item"><span>Salade de Pommes de Terre (E)</span><span className="price">70kr</span></div>
                    <div className="item"><span>French fries and aioli (E)</span><span className="price">55kr</span></div>
                    <div className="item"><span>Endive Salad (L)(N)</span><span className="price">65kr</span></div>
                    <div className="item"><span>Levain & Butter (G)(L)</span><span className="price">35kr</span></div>
                </div>
                
                <div className="section">
                    <h2>Drinks</h2>
                    <p className="note">(N) Nuts (E) Eggs (L) Lactose (G) Gluten (V) Vegan (*) Can make Vegan</p>
                    <h3>White</h3>
                    <div className="item"><span>2022 Camera Bianco, IT</span><span className="price">175/850kr</span></div>
                    <div className="item"><span>2021 Retour de Pêche Muscadet, Loire, FR</span><span className="price">185/920kr</span></div>
                    <div className="item"><span>2022 Petit Chablis, Closerie des Alisiers, FR</span><span className="price">195/950kr</span></div>
                    <div className="item"><span>2021 Frey-Sohler, Riesling Vieilles Vignes, FR</span><span className="price">195/960kr</span></div>
                    <div className="item"><span>2022 Sauvignon Blanc, Barboulot, FR</span><span className="price">860kr</span></div>
                    <div className="item"><span>2019 Chardonnay, True Myth Winery, USA</span><span className="price">989kr</span></div>
                    <div className="item"><span>2022 Riesling, FJ Gritsch, Smaragd, AT</span><span className="price">1600kr</span></div>
                    <div className="item"><span>2019 Albariño, Albamar, Rias Baixas, ES</span><span className="price">980kr</span></div>
                    <div className="item"><span>2017 Rovello Bianco Cantina Lonardo, Burlesque, Campania IT</span><span className="price">1100kr</span></div>
                    <div className="item"><span>2019 Santa Maria La Nave, Millesulmare, Etna Sicilia IT</span><span className="price">1900kr</span></div>
                    <div className="item"><span>2023 Sauvignon Blanc, Cloudy Bay, NZ</span><span className="price">1250kr</span></div>
                    <div className="item"><span>2022 Chardonnay, Spoke, NZ</span><span className="price">1450kr</span></div>
                    <div className="item"><span>2021 Grüner Veltliner, Sepp Moser, AT</span><span className="price">1200kr</span></div>
                    <div className="item"><span>2019 Chablis Tête d’Or, Domaine Billaud-Simon, FR</span><span className="price">1350kr</span></div>
                    
                    <h3>Red</h3>
                    <div className="item"><span>2022 Camera Rosso, IT</span><span className="price">175/850kr</span></div>
                    <div className="item"><span>2022 Pinot Noit, Frey-Sohler, Alsace, FR</span><span className="price">180/910kr</span></div>
                    <div className="item"><span>2021 Flavio Saglietti, Langhe Nebbiolo, IT</span><span className="price">195/950kr</span></div>
                    <div className="item"><span>2021 Malbec, Terrazas De los andes, AR</span><span className="price">190/970kr</span></div>
                    <div className="item"><span>2018 Chianti Reserva, Tenuta San Jacopo, Toscana, IT</span><span className="price">990kr</span></div>
                    <div className="item"><span>2020 Primitivo Susumaniello, Cantine Sgarzi, Salento, IT</span><span className="price">980kr</span></div>
                    <div className="item"><span>2019 Flavio Saglietti, Barolo Brunate, IT</span><span className="price">1700kr</span></div>
                    <div className="item"><span>2021 Sangiovese Podere Casaccia, Priscus, Toscana IT</span><span className="price">1150kr</span></div>
                    <div className="item"><span>2020 Napa Valley Cabernet Sauvignon, Black stallion, USA</span><span className="price">1300kr</span></div>
                    <div className="item"><span>2021 Sonoma Zinfandel, Seghesio, USA</span><span className="price">1250kr</span></div>
                    <div className="item"><span>2019 Barolo, Luna Di Mezzanotte, IT</span><span className="price">1300kr</span></div>
                    <div className="item"><span>2021 Pinot Noir, Spoke, Central Otago, NZ</span><span className="price">1400kr</span></div>
                    <div className="item"><span>2016 Syrah, Lillian, USA</span><span className="price">3200kr</span></div>
                    <div className="item"><span>2015 Barbaresco, Riserva Spezie, Piemonte, IT</span><span className="price">2500kr</span></div>
                    <div className="item"><span>2019 Langhe Nebbiolo, La Spinetta, Piemonte, IT</span><span className="price">1300kr</span></div>
                    <div className="item"><span>2017 Cabernet Sauvignon, Shafer, Napa Valley, USA</span><span className="price">8600kr</span></div>
                    
                    <h3>Rosé</h3>
                    <div className="item"><span>2022 Whispering Angel, Château d’Esclans, Provence, FR</span><span className="price">195/950/2190kr</span></div>
                    
                    <h3>Champagne</h3>
                    <div className="item"><span>Stonneligh</span><span className="price">180/980kr</span></div>
                    <div className="item"><span>N.V Veuve Clicquot</span><span className="price">235/1300kr</span></div>
                    <div className="item"><span>N.V Veuve Clicquot M</span><span className="price">2950kr</span></div>
                    <div className="item"><span>N.V Veuve Cliqout Rosé</span><span className="price">1800kr</span></div>
                    <div className="item"><span>N.V Ruinart Blanc de Blanc</span><span className="price">2500kr</span></div>
                    <div className="item"><span>Krug</span><span className="price">5950kr</span></div>
                    <div className="item"><span>Dom Perignon</span><span className="price">4990kr</span></div>
                    <div className="item"><span>Dom Perignon M</span><span className="price">11950kr</span></div>
                    <div className="item"><span>Armand de Brignac Brut Gold</span><span className="price">5950kr</span></div>
                    <div className="item"><span>2012 Salon Le Mesnil Blanc de Blancs</span><span className="price">19900kr</span></div>
                    
                    <h3>Non alcoholic</h3>
                    <div className="item"><span>Oddbird Blancs de Blancs</span><span className="price">95kr</span></div>
                </div>
                
                <p className="note">Note! Oysters & mussels are so-called high-risk foods that can cause unpleasant or allergic reactions (obviously not infrequently in combination with alcohol).</p> */}
                <p className="text-center mt-5">
                    <Button text='Make a Reservation'
                            class_type="primary"
                            onClick={() => window.open('https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0')}/>
                </p>
            </section>
        </>
    )
}