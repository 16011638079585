import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../components/button/Button';

export const Drinks = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Drinks - Hallwylska Restaurang</title>
                <meta name="description" content="Explore the drinks available at Hallwylska Restaurang." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/drinks" />
                <meta property="og:title" content="Drinks - Hallwylska Restaurang" />
                <meta property="og:description" content="Explore the drinks available at Hallwylska Restaurang." />
                <meta property="og:image" content="https://hallwylskarestaurang.com/img/menu/christmas_drinks/framsida.png" />
                <meta property="og:url" content="https://hallwylskarestaurang.com/drinks" />
                <meta name="twitter:title" content="Drinks - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Explore the drinks available at Hallwylska Restaurang." />
                <meta name="twitter:image" content="https://hallwylskarestaurang.com/img/menu/christmas_drinks/framsida.png" />
                
            </Helmet>
            <section className="menu container pt-5">
                <img src="/img/menu/bar-aug-1.png" 
                     alt="A la carte at hallwylska"/>
                <img src="/img/menu/bar-aug-2.png" 
                     alt="A la carte at hallwylska"/>
                
                <p className="text-center pb-5">
                    <Button text='Make a Reservation'
                            class_type="primary"
                            onClick={() => window.open('https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0')}/>
                </p>
            </section>
        </>
    )
}