import React from "react";

import { Helmet } from "react-helmet";

import './Home.css';
import Button from "../../components/button/Button";

export const Home = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Hallwylska Restaurang</title>
                <meta name="description" content="Experience the unique atmosphere and the fantastic cuisine at Hallwylska Restaurang." />
                <link rel="canonical" href="https://hallwylskarestaurang.com" />
                <meta property="og:title" content="Hallwylska Restaurang" />
                <meta property="og:description" content="Experience the unique atmosphere and the fantastic cuisine at Hallwylska Restaurang." />
                <meta property="og:url" content="https://hallwylskarestaurang.com" />
                <meta name="twitter:title" content="Hallwylska Restaurang" />
                <meta name="twitter:description" content="Experience the unique atmosphere and the fantastic cuisine at Hallwylska Restaurang." />
            </Helmet>
            <section className="landing-wrap">
                <div className="container">
                    {/* <h1 className="title pt-5 pb-3 text-center text-green" style={{fontWeight: '600'}}>We will be back in May!</h1> */}
                    <div className="landing">
                        <img src='/img/landing.webp' 
                            alt='Three tables and spilling wine on one of them with a tree in the background' 
                            className='landing-image fade-in'
                            width="648"
                            height="362"/>
                        <Button text='Make a Reservation'
                                class_type="primary"
                                onClick={() => window.open('https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0')}/>
                    </div>
                </div>
            </section>
        </>
    )
}